import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [];

export const dictionary = {
		"/": [11],
		"/(phonex)/account": [24,[6],[7]],
		"/autopromo/[spotUuid]": [49],
		"/(largex)/bulkrentals": [12,[2]],
		"/(largex)/bulkrentals/[id]": [13,[2]],
		"/(largex)/bulkrentals/[id]/admin": [14,[2]],
		"/(largex)/bulkrentals/[id]/billing": [15,[2]],
		"/(phonex)/login": [25,[6],[7]],
		"/(phonex)/occupancy": [26,[6],[7]],
		"/(phonex)/onboarding": [27,[6],[7]],
		"/(phonex)/rentals": [28,[6],[7,8]],
		"/(phonex)/rentals/[id]": [29,[6],[7,8]],
		"/(phonex)/spots/[id]": [30,[6],[7]],
		"/(phonex)/support": [31,[6,9],[7]],
		"/(phonex)/support/autostarts": [32,[6,9],[7]],
		"/(phonex)/support/autostarts/deny": [33,[6,9],[7]],
		"/(phonex)/support/autostarts/full-refund": [34,[6,9],[7]],
		"/(phonex)/support/autostarts/partial-refund": [35,[6,9],[7]],
		"/(phonex)/support/autostarts/success": [36,[6,9],[7]],
		"/(phonex)/support/rentals": [37,[6,9],[7]],
		"/(phonex)/support/rentals/deny": [38,[6,9],[7]],
		"/(phonex)/support/rentals/refund": [39,[6,9],[7]],
		"/(phonex)/support/rentals/success": [40,[6,9],[7]],
		"/(phonex)/support/violations": [41,[6,9],[7]],
		"/(phonex)/support/violations/deny": [42,[6,9],[7]],
		"/(phonex)/support/violations/discount": [43,[6,9],[7]],
		"/(phonex)/support/violations/success": [44,[6,9],[7]],
		"/(phonex)/support/violations/waive": [45,[6,9],[7]],
		"/(largex)/tenants": [16,[2,3]],
		"/(largex)/tenants/[tenant_id]": [17,[2,3,4]],
		"/(largex)/tenants/[tenant_id]/billing": [18,[2,3,4]],
		"/(largex)/tenants/[tenant_id]/bulk-rentals": [19,[2,3,4]],
		"/(largex)/tenants/[tenant_id]/bulk-rentals/[bulk_rental_id]": [20,[2,3,4,5]],
		"/(largex)/tenants/[tenant_id]/bulk-rentals/[bulk_rental_id]/admin": [21,[2,3,4,5]],
		"/(largex)/tenants/[tenant_id]/discount-codes": [22,[2,3,4]],
		"/(largex)/tenants/[tenant_id]/visitors": [23,[2,3,4]],
		"/(phonex)/validation-success": [46,[6],[7]],
		"/(phonex)/violations": [47,[6,10],[7]],
		"/(phonex)/violations/[uuid]": [48,[6,10],[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';